.navbar {
  height: 40%;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;
}

.nav-btn {
  background-color: none;
  text-decoration: none;
  color: inherit;
  font-size: 1.5em;
  /* color: var(--color-brand); */
  font-size: xx-small;
  text-transform: uppercase ;
  text-align: center;
  transition: all 0.5s ease; /* Add transition for hover effects */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;


  max-height: var(--header-thickness); 
  height: 20%;
  min-height: 5%;
  padding: 1px;
  /* width: 95%; */
  /* height: 50px; */
  /* padding: 40% 0 40%; */
  /* border-color: var(--color-brand); */
  /* outline: 1px solid var(--color-brand); */
}

.nav-btn-current {
  /* border-right: 6px solid var(--color-brand); */
  border-radius: 12% 0 0 12%;
  background-color: var(--color-background);
  background-color: rgba(0, 128, 128, .3);

  
}

/* .nav-btn:hover {
  background-color: var(--color-brand);
  color: var(--color-nav);

} */
.nav-btn svg[style]  {
  fill: var(--color-brand);
  color: var(--color-brand);
  /* background-color: lawngreen; */
}