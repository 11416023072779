.folio-header {
  color: var(--color-brand);
  background: var(--color-nav);

  /* width: 60px; */
  height: 100vh;
  position: fixed;
  width: var(--header-thickness);
  top: 0;
  flex: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;

  /* outline: 3px solid springgreen; */

  /* min-height: 500px; */

}

.social-media {
  /* height: 40%; */
  /* width: 98%; */
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;
  /* border: 1px solid lightgray; */
  
  
  margin: 0 0 100% 0;
}

.logo-msg {
  padding: 0 2px ;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-style: italic;
  font-size: x-small;
  color:  gold;
  text-align: center;
  background-color: teal;
  border-radius: 3px;
  margin-top: 2px;
  animation: blink 3s ease infinite;
}

.logo-msg--animation-delay {
  animation-delay: 1.5s;
}
.logo-msg-2 { animation-delay: 1.5s; }
.logo-msg-3 { animation-delay: 3.0s; }

@keyframes blink {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

@media only screen and (max-height: 420px) and (orientation: landscape) {
.social-media {
  margin: 0 0 0 0;
  }
}

.social-media * {
  color: inherit;
  /* padding: 5px; */
  padding: 1px;
  text-decoration: none;
  text-align: center;
  height: 50%
}