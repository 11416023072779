:root {
  --color-background: #1d1d1d;
  --color-brand: #08fdd8;
  --color-main: antiquewhite;
  --color-nav: #181818; 
  --header-thickness: 60px;
}

/* FOR REFERENCE: All imported fonts in index.html */
/* 
font-family: 'Indie Flower', cursive;
font-family: 'Martel', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Satisfy', cursive;
font-family: 'Titillium Web', saans-serif;
font-family: 'Ubuntu', sans-serif; 
*/

* {
  /* Scroll bar for Firefox */
  scrollbar-width: auto;          /* "auto" or "thin" */
  scrollbar-color: rgba(0, 128, 128, 0.7) rgba(0, 128, 128, 0.15);   /* scroll thumb and track */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Ubuntu', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--color-main);
  background-color: var(--color-background);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Martel', serif;
  /* width: 100%; */
  color: var(--color-brand);
}

h1 {
  font-size: 2.5rem;
  animation: block-translate-right 750ms ease-in-out both;
}

p {
  color: var(--color-main);
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  max-width: 700px;
  line-height: 1.5rem;
  padding: 0.5%;
  /* transform: translateX(100vw); */
  animation: block-translate-right 750ms ease-in-out 750ms both;
  animation: paragraph 750ms ease-in-out 750ms both;
  
}

p:hover {
  background-color: rgb(0,128,128, 0.4);
  border-radius: 5px;
  /* padding-left: 20px; */
}

a, a:visited, a:hover, a:active {
  color: var(--color-brand);
  text-decoration: none;
}

a:active {
  background-color: teal;
  border-radius: 50%;
}

::selection {
  color: #FFF714;
  background: deeppink;

}

svg {
 min-width: 20px;
 height: 100%;
 padding: 0;
 margin: 0;
 /* color: inherit; */
 /* fill: inherit; */
}

/* Scroll bar for all but Safari and ms*/

::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: rgba(0, 128, 128, 0.15);        /* color of the tracking area */
}
::-webkit-scrollbar-track:hover {
  background: rgba(0, 128, 128, 0.3);        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 128, 128, .7);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 5px solid rgba(0, 128, 128, 0.15);  /* creates padding around scroll thumb */
}
/* ::-webkit-scrollbar-thumb:hover {
  border: 5px solid rgba(0, 128, 128, 0.3);
} */

/* Animations */

@keyframes block-translate-right {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes paragraph {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fade-in {
  0%     { opacity: 0; }
  100%   { opacity: 1; }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 750ms;
  /* animation-direction: ; */
  animation-timing-function: linear;
  animation-fill-mode: both ;
}