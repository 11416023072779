/* class .main-page{} is in App.css */

.main-page.home {
  line-height: 1.12;
  text-align: left;

  width: 100vw;
  height: calc(100vh - (1% + var(--header-thickness)));

  display: flex;
  justify-content: space-around;
  align-self: center;

  animation: page-change-home 1s ease-in-out;
}

.greet-card {
  padding-left: 4vw;
  border-left : 2vw solid teal;
  border-radius: 1vw;
}

.greeting {
  font-size: 2rem;  
}

.welcome {
  font-size: 1.8rem;
}

.intro {
  font-size: 1.5rem;  
}

.name {
  --size: 8vh;
  font-size: var(--size);
  height: var(--size);

  width: auto;
  margin: 1% 0;
  padding: 0 0 2% 2%;

  display: flex;
  flex-direction: row;
}

.name svg {
  background-color: teal;
  border-radius: 10px;
  padding: 0 1%;
  margin-right: 1%;
}

.hatem {
  /* color: #2a9d8f; */
  color: #e9c46a;
  background-color: teal;

  font-family: 'exo';
  font-style: italic;
  font-weight: 700;
  text-transform: capitalize;

  border-radius: 10px;
  padding: 0 3%;
  
  transition: color 1s ease-in-out;
  /* animation: hatem 4s ease-in-out 500ms 2 ; */
  
}

/* .hatem:hover {
  -webkit-animation: hatem 4s ease-in-out 500ms 2 ;
  animation: hatem 10s none 0s infinite ;
  
} */

@keyframes hatem {
  /* 00% {color: #08fdd8;} */
  20% {color: #e9c46a ;}
  40% {color: #264653;}
  60% {color: #f4a261;}
  80% {color: #2a9d8f;}
  99% {color: #e76f51;}
  100% {color: #08fdd8;}
  
}

.jobTitle {
  font-size: 2rem;
}

.tagline {
  max-width: none;

  font-family: 'Pacifico';
  font-size: x-large;
  
  animation: fade-in 1s linear 5s both;
}

@media only screen and (max-width: 385px) {
  .greet-card {
    padding-left: 1%;
  }
  .name {
    --size: 6.5vh;
  }
}