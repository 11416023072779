.lang-box {
  height: 40px;
  width: 40px;
  width: 60px;
  text-align: center;
  color: blanchedalmond;
  /* border: none; */
  border-radius: 15px;
  border: 1.5px solid whitesmoke;
  /* border: 1.5px solid teal; */
  outline:none;
  position: fixed;
  top: 1em;
  right: 1.25em;
  /* left: 50%; */
  right: 5%;
  transition: all 0.8s ease-out; /* Add transition for hover effects */
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0.5;

}

.lang-box:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  opacity: 1;
}

.lang-box > * {
  padding: 5%;
}

.lang-box.en {
  background-color: red;
}

.lang-box.fr {
  background-color: darkblue;
}
