.main-page.portfolio {
  /* margin: 1%; */
  padding: 0.5%;
  flex: 1;
  /* height: 100vh; */
  overflow-y: auto; 
  scroll-behavior: smooth;
  height: 90vh;
}

.projects-list {
  /* height: inherit; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

/* Card View */
.card {
  border-radius: 10px;
  background-color: teal;
  border: 3px solid darkgray ;
  box-shadow: 
    0 4px 20px 0, 
    0 6px 20px 0;
  margin: 20px;
  padding: 2px;
  /* width: 10%; */
  width: 300px;
  font-size: 0.75rem ;
  color: black;
  /* flex: 10%; */
  transition: transform 250ms ease ;
  /* animation: vibrate 800ms linear 1s 1;  */  /* <-- This makes the :hover one not work */

  
}

.card:hover {
  box-shadow:  
  4px 4px 8px 0 rgba(0, 255, 0, 0.2), 
  0 6px 20px 0 rgba(0, 255, 0, 0.19);
  border-color: var(--color-brand);
  /* transform: scale(1.02) rotate(-1deg); */
  /* animation: vibrate 1000ms ease-in-out 1; */
}


@keyframes vibrate {
  0% {
    transform: scale(1) rotate(0deg);
  }
  33% {
    transform: scale(1.02) rotate(-1deg);
  }
  67% {
    transform: scale(1.02) rotate(1deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
  
}

.card h1 {
  color: lime;
  font-size: 1rem;
  text-align: center;

}

.card > img {
  object-fit: contain;
  margin-top: 1%;
  border-radius: 12px 12px ;
  width: 100%;
  height: 33%;
  max-height: 160px;
  box-shadow: 0 10px 20px -15px;
    /* 0 4px 8px 0 rgba(0, 0, 0, 0.2), 
    0 6px 20px 0 rgba(0, 0, 0, 0.19); */

}

.card hr {
  border-width: 0.1px;
  width: 90%;

}

.card > .description {
  height: 100px;
  color: lightgrey;
  font-size: 1em;
  margin: 4%;
  text-align: center;
  line-height: unset;
  overflow-y: scroll;
}

.card footer {
  display: flex;
  justify-content: space-between;
  height: 10%;
  /* font-size: 2rem;  */ /* <-- this is the one making the external link icon oversized*/
  align-self: flex-end;
  flex-shrink: 0 ;
  fill: ivory;
  margin: 1%;
  padding: 1%;
  box-shadow: 0 -10px 20px -15px;
}

.stack-list {
  /* color: black; */
  font-size: 1rem;
  /* font-variant: small-caps; */
  padding: 1%; 
  display: flex;
  justify-content: center;
  width: 100%;
}

.stack-list * {
  /* color:greenyellow; */
  fill: ivory;
  /* background-color: blue; */
  /* font-size: 1rem; */
  /* height: 100%; */
  margin: 0 2% 0 2%;
}

.card svg {
  height:"32px" !important;
  width: "32px"!important;
}



.card a:link {
  text-decoration: none;
  color: lime;
}

.card a:visited {
  text-decoration: none;
  color: lime;
}

.card a:hover {
  text-decoration: underline;
  background-color: darkgreen;
  border-radius: 3px;
}

.card a:active {
  text-decoration: underline;
}