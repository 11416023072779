.App {
  /* text-align: center; */
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  background-color: var(--color-background);
}

.main-page {
  /* position: relative;
  left: var(--header-thickness); */
  margin: 
    var(--header-thickness) 
    1em
    0em
    calc(var(--header-thickness)*1.2);
  padding: 2%;
  padding-top: 0;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  height: 100vh;
  height: 88vh;
  /* animation: page-change 1s ease-in-out; */
}

.col-2 {
  display: flex;
  justify-content: space-evenly;
  justify-content: space-between;
	align-items: center;
  padding: 0 10%;
  
}



@keyframes page-change {
  0% {
    transform: translate(-100vw, -10%);
    transform: translateX(-100%);
  }
  100% {
    transform: translate(0vw, 0vh);
    transform: translateX(0%);
  }
}

@keyframes page-change-home {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
} 


@media only screen and (max-width: 425px) {
  .main-page h1 {
    font-size: 1.5rem;
  }

  .main-page p {
    font-size: .8rem;
  }
}

/* .App h1 {
  width: 100%;
  color: #08fdd8;
}

.App p {
  color: antiquewhite;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
